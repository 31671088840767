import {Fragment, useEffect, useState} from "react";
import './App.css';
import Navigation from "./components/Navigation";
import Title from "./Title";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Input from "@mui/material/Input";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// Icons
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete'
import CancelIcon from '@mui/icons-material/Cancel';

import {UserIdToFriendly} from "./App";
import Layout from "./components/Layout";
import {Button, Modal, TextField, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {
    Box,
} from "@mui/material";
import SetTitle from "./components/SetTitle";

const CustomTableCell = ({ row, name, onChange }) => {
    const { isEditMode } = row;
    return (
        <TableCell>
            {isEditMode ? (
                <Input
                    value={row[name]}
                    name={name}
                    onChange={e => onChange(e, row)}
                />
            ) : (
                row[name]
            )}
        </TableCell>
    );
};
const createData = (id, ip, created_by, created_at, updated_at, notes) => ({
    id: id,
    ip: ip,
    created_by: created_by,
    created_at: created_at,
    notes: notes,
    updated_at: updated_at,
    isEditMode: false
});

function UpdateIPs(ips, setIPs, ip) {
    // this function takes a mutated ip as an input and updates the ips object with the new ip
    const localIPs = ips
    for (let obj of localIPs) {
        if (obj.id === ip.ip) {
            obj = createData(ip.ip, ip.ip,ip.created_by,ip.created_at,ip.updated_at,ip.notes);
            break;
        }
    }
    setIPs(localIPs)
}

// todo: check for how row.id is used. this could be incorrectly referenced, if a user updates a row.
function IPsTable(props) {

    const [previous, setPrevious] = useState({})

    const onDelete = id => {
        // we only passed the `id` value in so we need to get the IP address out of the row
        // we iterate over the rows to find the id and then the IP in the row
        // after we delete the ip, we then use `filter` to remove it from the table.
        props.rows.map(row => {
            if (row.id === id) {
                const deleteRequestOptions = {
                    method: 'DELETE'
                }
                const deleteUrl = '/api/v1/ips/' + row.ip
                fetch(deleteUrl, deleteRequestOptions)
                    .then(response => response.json())
                props.setRows(props.rows.filter(row => row.id !== id))
            }
        })
    }

    const onSave = id => {
        props.setRows(state => {
            return props.rows.map(row => {
                if (row.id === id) {
                    if (row.isNewIP === true) {
                        const requestOptions = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                "ips": [
                                    {
                                        "ip": row.ip,
                                        "notes": row.notes
                                    }
                                ]
                            })
                        };
                        const url = '/api/v1/ips'
                        fetch(url, requestOptions)
                            .then(response => response.json())
                            .then(data => UpdateIPs(props.rows, props.setRows, data))
                        // for some reason the return does not update the current row, so we need to set the following two values here.
                        row.id = row.ip
                        row.isEditMode = false
                        return { ...row, isEditMode: false}
                    } else {
                        // determine if IP field has changed. If so, delete old IP and create a new one
                        if (previous[row.id].ip !== row.ip) {
                            const deleteRequestOptions = {
                                method: 'DELETE'
                            }
                            const deleteUrl = '/api/v1/ips/' + previous[row.id].ip
                            fetch(deleteUrl, deleteRequestOptions)
                                .then(response => response.json())

                            const requestOptions = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    "ips": [
                                        {
                                            "ip": row.ip,
                                            "notes": row.notes
                                        }
                                    ]
                                })
                            };
                            const url = '/api/v1/ips'
                            fetch(url, requestOptions)
                                .then(response => response.json())
                                .then(data => UpdateIPs(props.rows, props.setRows, data))
                            // for some reason the return does not update the current row, so we need to set the following two values here.
                            // row.id = row.id
                            row.isEditMode = false
                            return { ...row, isEditMode: false};

                        }
                        else {
                            // if user did not change IP, just update the notes field.
                            const requestOptions = {
                                method: 'PUT',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    "ip": row.ip,
                                    "notes": row.notes
                                })
                            };
                            const url = '/api/v1/ips/' + row.id
                            fetch(url, requestOptions)
                                .then(response => response.json())
                                .then(data => UpdateIPs(props.rows, props.setRows, data))
                            // for some reason the return does not update the current row, so we need to set the following two values here.
                            row.id = row.ip
                            row.isEditMode = false
                            return { ...row, isEditMode: false};
                        }



                }}
                return row;
            });
        });
    };

    const onToggleEditMode = id => {
        props.setRows(state => {
            return props.rows.map(row => {
                if (row.id === id) {
                    return { ...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };

    const onChange = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({ ...state, [row.id]: row }));
        }
        const value = e.target.value;
        const name = e.target.name;
        const { id } = row;
        const newRows = props.rows.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        props.setRows(newRows);
    };

    const onRevert = id => {
        const newRows = props.rows.map(row => {
            if (row.id === id) {
                return previous[id] ? previous[id] : row;
            }
            return row;
        });
        props.setRows(newRows);
        setPrevious(state => {
            delete state[id];
            return state;
        });
        onToggleEditMode(id);
    };

    const onAdd = () => {
        props.setRows(current => [...current, {
            id: Math.floor(Math.random() * 1000000),
            ip: "",
            created_by: "",
            created_at: "",
            notes: "",
            updated_at: "",
            isEditMode: true,
            isNewIP: true
        }])
    }

    return (
        <div>
            <Box sx={{ paddingBottom:'10px' }}>
                {/*<Button variant="contained" startIcon={<AddIcon />} onClick={() => onAdd()}>Add an IP</Button>*/}
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => props.setIsAddIPsModalOpen(true)}>Add IPs</Button>
            </Box>
        <TableContainer component={Paper}>
        <Table size="small" style={{ width: "100%" }}>
            <TableBody>
                <TableRow >
                    <TableCell>IP</TableCell>
                    <TableCell>Added By</TableCell>
                    <TableCell>Added On</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                { props.rows.map(row => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <CustomTableCell {...{ row, name: "ip", onChange }} />
                        <TableCell component="th" scope="row">{UserIdToFriendly(row.created_by, props.users)}</TableCell>
                        <TableCell component="th" scope="row">{row.created_at}</TableCell>
                        <CustomTableCell {...{ row, name: "notes", onChange }} />
                        <TableCell component="th" scope="row">
                            {row.isEditMode ? (
                                <>
                                    <IconButton
                                        aria-label="save"
                                        onClick={() => onSave(row.id)}
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="revert"
                                        onClick={() => onRevert(row.id)}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => onToggleEditMode(row.id)}
                                >
                                    <EditIcon />
                                </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => onDelete(row.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>
        </div>
)
}

function updateTableWithNewIPs(new_ips, rows, setRows){
    // this function
    // 1. takes the existing rows in the table and makes a copy of it
    // 2. pushes the new IPs to it
    // 3. makes the local copy the copy in the table
    let localRows = rows
    for (let ip of new_ips) {
        localRows.push(createData(ip.ip, ip.ip,ip.created_by,ip.created_at,ip.updated_at,ip.notes))
    }
    setRows(localRows)
}

function newIpsResponseView(props){
    // todo: come back and add conditional formatting
    return (
        <Box>
            <Box>The following IPs had errors:</Box>
            { props.ips.errors.map(ip => (
                <li><b>{ip.input_as}</b>: {ip.error}</li>
                ))
            }<br />
            <Box>The following IPs already existed:</Box>
            { props.ips.existed.map(ip => (
                <li>{ip.ip} ({ip.input_as})</li>
            ))
            }<br />
            <Box>The following IPs were successfully added:</Box>
            { props.ips.added.map(ip => (
                <li>{ip.ip} ({ip.input_as})</li>
            ))
            }
        </Box>)}

function ipsAction(ips, rows, setRows){
// function ipsAction(ips, rows, setRows){
    updateTableWithNewIPs(ips.added, rows, setRows)
    // setResponse(newIpsResponseView(ips={ips}))
    // todo: in the future display the response.
    // console.log("the following IPs had errors")
    // console.log(ips.errors)
    // console.log("the following IPs already existed")
    // console.log(ips.existed)
    // console.log("the following IPs were added")
    // console.log(ips.added)
}

function addIPs(body, rows, setRows, handleClose){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'text/plain'},
        body: body
    }
    const url = '/api/v1/ips'
    fetch(url, requestOptions)
        .then(response => response.json())
        // todo: show
        .then(data => ipsAction(data, rows, setRows))
        .then(handleClose)
        // .then(data => ipsAction(data, rows, setRows))
}

function AddIPsModal(props) {
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '15rem',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => props.setIsAddIPsModalOpen(false);

    const [newIPs, setNewIPs] = useState()

    return (
        <Modal
            open={props.isAddIPsModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Box><b>Add IPs</b></Box>
                <Box sx={{ padding:'1rem', textAlign: 'center' }}>
                    <TextField multiline placeholder={"8.8.8.8\n9.9.9.9/32\n4.3.2.1/30"} onChange={(v) => setNewIPs(v.target.value)}></TextField>
                </Box>
                {/*<Box sx={{ border: '2px solid #000', padding:'.5rem'}}>*/}
                    {/*Each IP should be on its own line.<br/><br/>*/}
                    {/*CIDR notation is supported (e.g. 9.9.9.9/30). However, large subnets greater than 32 hosts are not supported.*/}
                {/*</Box>*/}
                <Box sx={{ paddingTop: '1rem', textAlign: 'right' }}><Button variant="outlined" onClick={handleClose}>Cancel</Button> <Button variant="contained" onClick={() => addIPs(newIPs, props.rows, props.setRows, handleClose)}>Add IPs</Button></Box>

            </Box>
        </Modal>
    )
}

function IPs() {
    SetTitle("IPs | Relay Hawk")
    // todo: lookup the "added by" user ID to resolve the Name or Email of the user.
    const [ips, setIPs] = useState([])
    const [rows, setRows] = useState([]);
    const [users, setUsers] = useState([]);

    const [isAddIPsModalOpen, setIsAddIPsModalOpen] = useState(false);

    useEffect(() => {
        fetch('/api/v1/ips')
            .then(res => res.json())
            .then(data => {
                setIPs(data.ips)
                let local_rows = []
                for (let ip of data.ips) {
                    local_rows.push(createData(ip.ip, ip.ip,ip.created_by,ip.created_at,ip.updated_at,ip.notes))
                }
                setRows(local_rows)
        });
    }, []);

    useEffect(() => {
        fetch('/api/v1/users').then(res => res.json()).then(data => {
            setUsers(data.users);
        });
    }, []);


    return (
        <Layout>
            <Title>IPs</Title>
            <AddIPsModal isAddIPsModalOpen={isAddIPsModalOpen} setIsAddIPsModalOpen={setIsAddIPsModalOpen} rows={rows} setRows={setRows}></AddIPsModal>
            <IPsTable ips={ips} rows={rows} setRows={setRows} setIsAddIPsModalOpen={setIsAddIPsModalOpen} users={users}/>
        </Layout>
  );
}

export default IPs;

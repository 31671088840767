import {Box} from "@mui/material";
import {Logo} from "./SignUp";
import SetTitle from "./components/SetTitle";

export default function ValidateEmail() {
    SetTitle("Validate Email | Relay Hawk")
    return (
        <Box sx={{
            background: '#0c5394',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center'
        }}>
            <Box sx={{
                width: '50rem',
                background: '#ffffff',
                borderRadius: '20px',
                display: 'block'
            }}>
                <Box sx={{
                    padding: '20px',
                    display: 'block',
                    alignItems: 'left',
                    textAlign: 'center',
                }}>
                    <Box sx={{ padding:'10px' }}>
                        <Logo />
                    </Box>
                    <Box sx={{ padding:'10px' }}>
                        Before you can access Relay Hawk, you must validate your email.
                        <br/><br/>
                        Please check your inbox for an email from "no-reply@auth0user.net" with the subject "Verify your email".
                        <br/><br/>
                        After verifying, please click <a href="https://app.relayhawk.com/login">here</a>.
                        <br/><br/>
                        If you are having trouble, please email support@relayhawk.com.
                    </Box>
                </Box>
                </Box>
        </Box>
    )

}
import React from 'react';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';
import {ReactComponent as LogoWhite} from '../icons/logo-white.svg'

export const NavbarLink = styled(NavLink)`
    display: block;
    color: #FFFFFF;
    padding: 1rem;
    text-decoration: none;
    &:hover {
        background-color: #093b69;
    }
    // border-bottom: 1px solid;
`

export const Nav = styled.div`
    // border-right: 1px solid;
    background-color: #0c5394;
    height: 100%;
`

export const NavLogo = styled(LogoWhite)`
    padding: 1rem;
    width: 10rem;
`

export default function Navigation(props) {
    return (
        <React.Fragment>
            <Nav>
                <NavLogo></NavLogo>
                <NavbarLink to="/">Home </NavbarLink>
                <NavbarLink to="/WebServices">Web Services</NavbarLink>
                <NavbarLink to="/ips">IPs</NavbarLink>
                <NavbarLink to="/users">Users</NavbarLink>
                <NavbarLink to="/logout">Logout</NavbarLink>
            </Nav>
        </React.Fragment>
    )
}
import './App.css';
import Navigation from "./components/Navigation";
import Title from "./Title";
import Paper from "@mui/material/Paper";
import {getWebServicesEmpty} from "./test_data_webservices";
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Modal,
    Box,
    Typography,
    Button,
    TextField,
    Autocomplete,
    TableHead,
    Tooltip
} from "@mui/material";
import {getIPsEmpty} from "./test_data_ips";
import {useState, useEffect, Fragment} from "react";
import styled from "styled-components";
import Layout from "./components/Layout";
import SetTitle from "./components/SetTitle";
import HttpIcon from '@mui/icons-material/Http';
import FolderIcon from '@mui/icons-material/Folder';
import LanIcon from '@mui/icons-material/Lan';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function Metrics(props) {
    return (
        <Box sx={{ display: 'flex', borderRadius: '15', width: '40rem'}}  component={Paper} elevation={6}>
            <Box sx={{padding: '1rem'}}>
                <Box sx={{ padding: '1rem', borderWidth: '1', borderColor: "#000000", borderRadius: '15px'}}>

                    <Box>
                        <b>{props.webServices.to_review_count}</b> Services need review
                    </Box>
                    <Box>
                        <b>{props.webServices.should_not_be_open_count}</b> Services should not be open to the Internet
                    </Box>
                    <Box>
                        <b>{props.webServices.should_be_open_count}</b> Services should be open to the Internet
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: '1rem' }}>
                <Box sx={{ padding: '1rem' }}>
                    <Box>
                        <b>{props.ips.ips.length}</b> IPs monitored
                    </Box>
                    <Box>
                        Next scan is <b>{props.scans.next_scan}</b>.
                    </Box>
                    <Box>
                        {/*<u>Run scan now</u>*/}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

function getReviewTitle(service) {
    const reviewTypes = {
        "to_review": "Need review",
        "should_not_be_open": "Should not be open",
        "should_be_open": "Should be open"
    }
    return (
        reviewTypes[service]
    )
}

function AssignmentText(props){
    const createText = function (assignedTo, text) {
        let final_text = ""
        if (assignedTo === null) {
            let final_text = "No one " + text
        } else {
            let final_text = assignedTo + " " + text
        }
        return (
            final_text
        )
    }

    return (
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {createText(props.assignedTo, props.text)}
        </Typography>
    )
}

function UsersAutoComplete(props){
    const userIdLabels = []
    for (const user of props.users){
        userIdLabels.push({
            label: UserIdToFriendly(user.id, props.users),
            id: user.id
        })
    }

    return (
        <Autocomplete
            disablePortal
            id="user-auto-complete"
            options={userIdLabels}
            sx={{ width: 300 }}
            defaultValue={props.currentUser}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label="Assign to" />}
            onChange={(e, value) => props.setModifiedAssignedTo(value.id)}
        />
    )
}

function AssignmentDiv(props){
    // note: this is not going to actually update the object.
    // what i really need to do is do a copy of the service itself
    const [modifiedAssignedTo, setModifiedAssignedTo] = useState(props.service.user_configuration.assigned_to)

    const setAssignedTo = function(value) {
        const newUserConfiguration = {...props.service.user_configuration, assigned_to: parseInt(value)}
        const newService = {...props.service, user_configuration: newUserConfiguration}
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newService)
        };
        const url = '/api/v1/services/'+ props.service.id
        // make the request and the update the webservices prop with the response
        // we will likely want to do some error handling here
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => UpdateWebServices(props.webServices, props.setWebServices, data))
        }

    const autoCompleteLabel = function(id){
        return ({
            label: UserIdToFriendly(id, props.users),
            id: id
        })
    }

    return (<Box sx={{ textAlign: 'center',  alignItems: 'center', justifyContent: 'center' }} >
        {props.shouldBeOpen === "no" &&
                <AssignmentText assignedTo={UserIdToFriendly(props.service.user_configuration.assigned_to, props.users)} text="has been assigned to remove this service from the Internet."></AssignmentText>
        }
        {props.shouldBeOpen === "unsure" &&
                <AssignmentText assignedTo={UserIdToFriendly(props.service.user_configuration.assigned_to, props.users)} text="has been assigned to review whether this service should be open to the Internet."></AssignmentText>
        }
        <Box sx={{ 'textAlign': 'center', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Box sx={{ padding:'5px' }}>
            <UsersAutoComplete currentUser={autoCompleteLabel(props.service.user_configuration.assigned_to)} setModifiedAssignedTo={setModifiedAssignedTo} users={props.users}></UsersAutoComplete>
        </Box>
        <Box sx={{ padding:'5px' }}><Button variant="contained" onClick={() => setAssignedTo(modifiedAssignedTo)}>Assign</Button></Box>
        </Box>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Assigning someone sends them an email notifying them that this service needs to be reviewed by them.
    </Typography>
    </Box>)
}

function Assignment(props) {

    let display = false

    // logic:
    // Do *NOT* display assignment div:
    //   * if state is not set, do not display assignment
    //   * if it is supposed to be open, do not display assignment
    // Display the assignment div:
    //   * if it is not supposed to be open
    //   * if someone is unsure
    if (props.shouldBeOpen === null || props.shouldBeOpen === "yes") {
    } else if (props.shouldBeOpen === "unsure" || props.shouldBeOpen === "no") {
        display = true
    } else {
    }

    return (
        <div>
        {display ? (<AssignmentDiv shouldBeOpen={props.shouldBeOpen} service={props.service} webServices={props.webServices} setWebServices={props.setWebServices} users={props.users}></AssignmentDiv>)
            :<div></div>}
        </div>
    )
}

function ServiceUserConfiguration(props) {

    const OpenToInternetButton = function(props) {
        if (props.button === "unsure") {
            if (props.service.user_configuration.should_be_open === "unsure") {
                return (<Button color="info" variant="contained" onClick={() => setShouldBeOpen("unsure")}>I'm
                    Unsure</Button>)
            } else {
                return (<Button color="info" variant="outlined" onClick={() => setShouldBeOpen("unsure")}>I'm
                    Unsure</Button>)
            }
        } else if (props.button === "yes") {
            if (props.service.user_configuration.should_be_open === "yes") {
                return (<Button color="success" variant="contained" onClick={() => setShouldBeOpen("yes")}>yes</Button>)
            } else {
                return (<Button color="success" variant="outlined" onClick={() => setShouldBeOpen("yes")}>Yes</Button>)
            }
        } else if (props.button === "no" ) {
            if (props.service.user_configuration.should_be_open === "no") {
                return (<Button color="error" variant="contained" onClick={() => setShouldBeOpen("no")}>No</Button>)
            } else {
                return (<Button color="error" variant="outlined" onClick={() => setShouldBeOpen("no")}>No</Button>)
            }
        }
    }

    const setShouldBeOpen = function(value) {
        const newUserConfiguration = {...props.service.user_configuration, should_be_open: value}
        const newService = {...props.service, user_configuration: newUserConfiguration}
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newService)
        };
        const url = '/api/v1/services/'+ props.service.id
        // make the request and the update the webservices prop with the response
        // we will likely want to do some error handling here
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => UpdateWebServices(props.webServices, props.setWebServices, data))
    }

    return (
        <Box sx={{ 'backgroundColor':'#f2f7ff', 'borderRadius': '15px', padding: '10px'}}>
            <Typography id="modal-modal-description" sx={{ textAlign: 'center' }}>
                Is this supposed to be open to the Internet?
            </Typography>
            <Box sx={{'textAlign': 'center', display:'flex', width:1, alignItems: 'center', justifyContent: 'center'}} >
                <Box sx={{padding:'6px'}}>
                    <OpenToInternetButton button="no" service={props.service}></OpenToInternetButton>
                </Box>
                <Box sx={{padding:'6px'}}>
                    {/*<Button color="info" variant="contained" onClick={() => setShouldBeOpen("unsure")}>I'm Unsure</Button>*/}
                    <OpenToInternetButton button="unsure" service={props.service}></OpenToInternetButton>
                </Box>
                <Box sx={{padding:'6px'}}>
                    {/*<Button color="success" variant="contained" onClick={() => setShouldBeOpen("yes")}>Yes</Button>*/}
                    <OpenToInternetButton button="yes" service={props.service}></OpenToInternetButton>
                </Box>
            </Box>
            <Assignment service={props.service} shouldBeOpen={props.service.user_configuration.should_be_open} webServices={props.webServices} setWebServices={props.setWebServices} users={props.users}></Assignment>
        </Box>
    )
}

function Screenshot(props) {
    const service = props.service.service
    const ip = props.service.ip
    const port = props.service.port

    const altText = function () {
        return (
            "Screenshot of " + service + " on IP " + ip + " on port " + port + "."
        )
    }
    console.log(altText())

    return (
        <Tooltip title="A screenshot of the service.">
            <Box sx={{ textAlign: 'center', alignItems: 'center', }}>
                <Box
                    component="img"
                    sx={{
                        width: props.width,
                    }}
                    alt={altText()}
                    src={props.service.screenshot}
                />
            </Box>
        </Tooltip>
    )
}

function ScreenshotIcon(props) {
    // logic:
    // 1. if we have a screenshot, display it
    // 2. if we have an icon for the service, display it
    // 3. if we don't, display a generic service icon

    // 1. if we have a screenshot, display it
    if (props.service.screenshot) {
        return (
            <Box sx={{maxWidth: {xs: 350, md: 400}}}>
                <Screenshot service={props.service} width='5rem'></Screenshot>
            </Box>
        )

        // 2. if we have an icon for the service, display it
    } else if (props.service.service === "Web Server") {
        return <HttpIcon sx={{ fontSize: 60 }} />
    } else if (props.service.service === "Networking Service") {
        return <LanIcon sx={{ fontSize: 60 }} />
    } else if (props.service.service === "Remote Administration Service") {
        return <ConnectedTvIcon sx={{ fontSize: 60  }} />
    } else if (props.service.service === "File Server") {
        return <FolderIcon sx={{ fontSize: 60 }} />
    } else if (props.service.service === "VOIP Server") {
        return <PhoneEnabledIcon sx={{ fontSize: 60  }} />
    } else if (props.service.service === "Remote Desktop Server") {
        return <PersonalVideoIcon sx={{ fontSize: 60  }} />

        // 3. if we don't, display a generic service icon
    } else {
        return <LanguageIcon sx={{ fontSize: 60 }} />
    }
}

function ScreenshotModal(props) {
    if (props.service.screenshot) {
        return (
            <Box sx={{'paddingTop':'15px', 'paddingBottom':'15px'}}>
                <Screenshot service={props.service} width='.8'></Screenshot>
            </Box>
        )
    } else {
        return (<Fragment></Fragment>)
    }
}

function ReviewModalContents(props){
    let serviceName = props.service.service
    let ip = props.service.ip
    let port = props.service.port

    return (
        <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h5" component="h1" align="center" fontWeight="bold">
                You have a {serviceName} exposed to the Internet on IP {ip} on port {port}.
            </Typography>
            <ScreenshotModal service={props.service}></ScreenshotModal>
            <ServiceUserConfiguration service={props.service} setWebServices={props.setWebServices} webServices={props.webServices} users={props.users}></ServiceUserConfiguration>
            <Box sx={{ paddingTop: '1rem' }}>
                {props.service.overview &&
                    <Box sx={{'backgroundColor': '#f2f7ff', borderRadius: '15px', padding: '1rem'}}>
                        <Typography fontWeight="bold">
                            {props.service.recommendation}
                        </Typography>
                        <Typography fontWeight="bold" paddingTop='1rem'>
                            Description
                        </Typography>
                        <Typography>
                            {props.service.overview}
                        </Typography>
                        {props.service.business_impact &&
                            <Fragment>
                                <Typography fontWeight="bold" paddingTop='1rem'>
                                    Business impact if compromised
                                </Typography>
                                <Typography>
                                    {props.service.business_impact}
                                </Typography>
                            </Fragment>
                        }
                    </Box>
                }
            </Box>
        </Box>
    )
}

function ReviewModal(props) {
    const handleClose = () => props.setIsReviewModalOpen(false);

    return (
        <Modal
            open={props.isReviewModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <ReviewModalContents service={props.service} setWebServices={props.setWebServices} webServices={props.webServices} users={props.users}></ReviewModalContents>
                </Typography>
            </Box>
        </Modal>
    )
}

function Review(props) {
    const handleOpen = () => openReviewModal(props.service, props.setCurrentService, props.setIsReviewModalOpen);

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>Review</Button>
        </div>
    );
}

function CreateServiceRow(props) {
    return (
        <TableRow key={props.service.id} sx={{width: '100%'}} >
        {/*<TableRow key={props.service.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
            <TableCell component="th" scope="row" sx={{textAlign: "center"}}>
                {/*<Box*/}
                {/*    component="img"*/}
                {/*    sx={{*/}
                {/*        maxHeight: { xs: 233, md: 167 },*/}
                {/*        maxWidth: { xs: 350, md: 200 },*/}
                {/*    }}*/}
                {/*    alt="Screenshot of ."*/}
                {/*    src={props.service.screenshot}*/}
                {/*/>*/}

                <ScreenshotIcon service={props.service}></ScreenshotIcon>
            </TableCell>
            <TableCell component="th" scope="row" sx={{width: '100%', paddingLeft: '0', paddingRight: '0'}}>
                <Box>
                    <Box sx={{display: 'flex', padding: '2px'}}>
                        <Box sx={{padding: '2px', 'fontWeight': 'bold'}}>Service:</Box>
                        <Box sx={{padding: '2px'}}>{props.service.service}</Box>
                    </Box>
                    <Box sx={{display: 'flex', padding: '2px'}}>
                        <Box sx={{display:'flex'}}>
                            <Box sx={{padding: '2px', 'fontWeight': 'bold'}}>IP:</Box>
                            <Box sx={{padding: '2px'}}>{props.service.ip}</Box>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', padding: '2px'}}>
                        <Box sx={{display:'flex'}}>
                            <Box sx={{padding: '2px', 'fontWeight': 'bold'}}>Port:</Box>
                            <Box sx={{padding: '2px'}}>{props.service.port}</Box>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', padding: '2px'}}>
                        <Box sx={{padding: '2px', 'fontWeight': 'bold'}}>Assigned to:</Box>
                        <Box sx={{padding: '2px'}}>{UserIdToFriendly(props.service.user_configuration.assigned_to, props.users)}</Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell component="th" scope="row">
                <Review service={props.service} setWebServices={props.setWebServices} webServices={props.webServices} setCurrentService={props.setCurrentService} setIsReviewModalOpen={props.setIsReviewModalOpen}></Review>
            </TableCell>
        </TableRow>
    )
}

function ReviewTable(props) {
    let reviewType = props.reviewType
    let title = getReviewTitle(reviewType)

    return (
        <Box sx={{alignItems: 'baseline'}}>
            <h3>{title}</h3>
            <TableContainer component={Paper} sx={{width: '100%'}}>
            <Table sx={{width: '27rem'}}>
                <TableBody>
                    { props.webServices.services.map(row => (
                        <Fragment>
                            { reviewType === "to_review" && <Fragment>
                                {row.user_configuration.should_be_open === "unsure" &&
                                    <CreateServiceRow service={row} setWebServices={props.setWebServices} webServices={props.webServices} setCurrentService={props.setCurrentService} setIsReviewModalOpen={props.setIsReviewModalOpen} users={props.users}></CreateServiceRow>
                                }
                                {row.user_configuration.should_be_open === null &&
                                    <CreateServiceRow service={row} setWebServices={props.setWebServices} webServices={props.webServices} setCurrentService={props.setCurrentService} setIsReviewModalOpen={props.setIsReviewModalOpen} users={props.users}></CreateServiceRow>
                                }</Fragment>
                            }
                            { reviewType === "should_not_be_open" && <Fragment>
                                {row.user_configuration.should_be_open === "no" &&
                                    <CreateServiceRow service={row} setWebServices={props.setWebServices} webServices={props.webServices} setCurrentService={props.setCurrentService} setIsReviewModalOpen={props.setIsReviewModalOpen} users={props.users}></CreateServiceRow>
                                }
                            </Fragment>
                            }
                            { reviewType === "should_be_open" && <Fragment>
                                {row.user_configuration.should_be_open === "yes" &&
                                    <CreateServiceRow service={row} setWebServices={props.setWebServices} webServices={props.webServices} setCurrentService={props.setCurrentService} setIsReviewModalOpen={props.setIsReviewModalOpen} users={props.users}></CreateServiceRow>
                                }
                            </Fragment>
                            }
                        </Fragment>
                        )
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        </Box>
    )
}

function UpdateWebServices(webServices, setWebServices, service) {
    // this function takes a mutated service as an input and updates the webServices object with the new service
    // todo: update the logic to update the metrics OR maybe we just return it from the backend rather requiring
    // the frontend to compute all of this logic
    const localWebServices = webServices.services
    for (let obj of localWebServices) {
        if (obj.id === service.id) {
            obj.user_configuration = service.user_configuration
            break;
        }
    }
    setWebServices({...webServices, services: localWebServices})
}

const openReviewModal = function (service, setCurrentService, setIsReviewModalOpen) {
    setCurrentService(service)
    setIsReviewModalOpen(true)
}

export const UserIdToFriendly = function (user_id, users) {
    // const user = getUser(id)
    let friendlyName = ""
    for (let user of users) {
        if (user.id === user_id) {
            friendlyName = user.email
            if (user.first_name && user.last_name) {
                friendlyName = user.first_name + " " + user.last_name
            }
            break;
        }
    }
    return friendlyName
}

// export const PageContent = styled.div`
//     display: block;
//     padding-left: 1rem;
// `

function App() {
    SetTitle("Home | Relay Hawk")
    // const [webServices, setWebServices] = useState(getWebServices())
    const [webServices, setWebServices] = useState(getWebServicesEmpty())
    useEffect(() => {
        fetch('/api/v1/services').then(res => res.json()).then(data => {
            setWebServices(data);
        });
    }, []);
    const [scans, setScans] = useState({})
    useEffect(() => {
        fetch('/api/v1/scans').then(res => res.json()).then(data => {
            setScans(data);
        });
    }, [])

    // let ips = getIPs().ips
    const [ips, setIPs] = useState(getIPsEmpty())
    useEffect(() => {
        fetch('/api/v1/ips').then(res => res.json()).then(data => {
            setIPs(data);
        });
    }, []);

    const [users, setUsers] = useState([])
    useEffect(() => {
        fetch('/api/v1/users').then(res => res.json()).then(data => {
            setUsers(data.users);
        });
    }, []);

    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

    // this variable allows us to track what service should be displayed in the modal. When a user clicks on
    //   "review service" the current service variable is updated then the modal is opened.
    const [currentService, setCurrentService] = useState({})

    return (
        <Layout onboarding={true}>
            <Title>Home</Title>
            <ReviewModal isReviewModalOpen={isReviewModalOpen} setIsReviewModalOpen={setIsReviewModalOpen} service={currentService} webServices={webServices} setWebServices={setWebServices} users={users}></ReviewModal>
            <Metrics webServices={webServices} ips={ips} scans={scans}></Metrics>
            <Box sx={{ display: 'flex'}}>
                <Box>
                    <ReviewTable reviewType="to_review" webServices={webServices} setWebServices={setWebServices} setCurrentService={setCurrentService} setIsReviewModalOpen={setIsReviewModalOpen} users={users}></ReviewTable>
                </Box>
                <Box sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <ReviewTable reviewType="should_not_be_open" webServices={webServices} setWebServices={setWebServices} setCurrentService={setCurrentService} setIsReviewModalOpen={setIsReviewModalOpen} users={users}></ReviewTable>
                </Box>
                <Box>
                    <ReviewTable reviewType="should_be_open" webServices={webServices} setWebServices={setWebServices} setCurrentService={setCurrentService} setIsReviewModalOpen={setIsReviewModalOpen} users={users}></ReviewTable>
                </Box>
            </Box>
        </Layout>
    )
}

export default App;

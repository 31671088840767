import Navigation from "./Navigation";
import { Box, Button } from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

function Banner({ children, ...props }){
    return (
        <Box sx={{
            display: 'block',
            backgroundColor: props.color,
        }}>
            <Box sx={{
                padding: '1rem'
            }}>
                {children}
            </Box>
        </Box>
    )
}

function RescanBanner(props){
    const scan = {
        "last_scan": "",
        "next_scan": "",
        "scan_in_progress": false,
        "changes_since_last_scan": false
    }

    const [scanMeta, setScanMeta] = useState(scan)
    useEffect(() => {
        fetch('/api/v1/scans').then(res => res.json()).then(data => {
            setScanMeta(data)
        });
    }, []);
    const [bannerText, setBannerText] = useState("You have made changes to your configuration since your last scan. When you are ready to rescan your systems, click run scan. ")

    function runScan(){
        const url = "/api/v1/scans"
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({})
        };
        fetch(url, requestOptions)
            // todo: figure out how to hide banner after 3 seconds
            .then(() => setBannerText("Your scan has been queued."))
    }

    return (
        <Box>{scanMeta.changes_since_last_scan &&
            <Banner color='#eed202'>
                {bannerText}
                <Button variant="contained" onClick={() => runScan()}>
                    Run Scan
                </Button>
            </Banner>
        }</Box>
    )
}


function OnboardingBanner(props) {
    const navigate = useNavigate();

    // set initial state to 1 rather than 0 so it doesn't quickly flash the banner and then hide it once the API call is successful
    const [ips, setIPs] = useState(1)
    useEffect(() => {
        fetch('/api/v1/ips').then(res => res.json()).then(data => {
            setIPs(data.ips.length);
        });
    }, []);


    return (
        <Box>{ips === 0 &&
            <Banner color='#eed202'>
                The first step in your journey to secure your systems is for you to <b>identify all of your public IP addresses</b>.

                <p>Next, Relay Hawk <b>scans these IP addresses</b> for anything exposed to the Internet that an attacker can see.</p>

                <p>Afterward, Relay Hawk provides recommendations for you to <b>review</b> and assign users to <b>resolve</b> any issues.</p>

                <p>Finally, Relay Hawk <b>runs continuous scans</b> on these IP addresses to ensure that you know if someone accidentally exposes something new to the Internet. </p>

                <Button variant="contained" onClick={() => navigate('/IPs')}>
                    Configure IPs
                </Button>
            </Banner>
        }</Box>
    )
}

export default function Layout({ children, ...props }) {
    const navigate = useNavigate();

    function handleResponse(response){
        if (response.status === 401) {
            window.location.href = "/login"
        }
        return (
            response
        )
    }

    function handleRouting(account) {
        console.log(account)
        if (account.is_approved === true){
        }
        else if (account.name === null) {
            navigate('/complete-signup')
        }
        else {
            navigate('/signup-waiting-approval')
        }
    }



    // const [account, setAccount] = useState()

    useEffect(() => {
        fetch('/api/v1/accounts')
            .then(response => handleResponse(response))
            .then(response => response.json())
            // todo: support additional accounts. Right now it just takes the first account in the array
            .then(data => handleRouting(data.accounts[0]))
    }, []);
    return (
        <Box sx={{ display: "flex" }}>
            <Navigation />
            <Box sx={{
                display: 'block'
            }}>
                {/* onboarding banner can be optionally displayed */}
                {props.onboarding === true && <OnboardingBanner></OnboardingBanner>}
                <RescanBanner></RescanBanner>
                <Box sx={{
                    paddingLeft: '1rem',
                }}>
                    {children}
                </Box>
        </Box>
        </Box>
    )
}

import {useEffect, useState} from "react";
import './App.css';
import Navigation from "./components/Navigation";
import Title from "./Title";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Input from "@mui/material/Input";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// Icons
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import Layout from "./components/Layout";

import {UserIdToFriendly} from "./App";
import {Box, Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SetTitle from "./components/SetTitle";

const CustomTableCell = ({ row, name, onChange }) => {
    const { isEditMode } = row;
    return (
        <TableCell>
            {isEditMode ? (
                <Input
                    value={row[name]}
                    name={name}
                    onChange={e => onChange(e, row)}
                />
            ) : (
                row[name]
            )}
        </TableCell>
    );
};

const createData = (id, first_name, last_name, email, created_by, created_at, updated_at, is_active, isEditMode) => ({
    id: id,
    first_name: first_name,
    last_name: last_name,
    email: email,
    created_at: created_at,
    updated_at: updated_at,
    // todo: this is going to call the API one time for every user. Need to refactor
    created_by: created_by,
    is_active: is_active,
    isEditMode: isEditMode
});

function UpdateUsers(users, setUsers, user) {
    // this function takes a mutated user as an input and updates the users object with the new user
    const localUsers = users
    for (let obj of localUsers) {
        if (obj.id === user.id) {
            obj = createData(user.id, user.first_name, user.last_name, user.email, UserIdToFriendly(user.created_by, users), user.created_at, user.updated_at, user.is_active, false)
            break;
        }
    }
    setUsers(localUsers)
}

function UsersTable(props) {
    const [previous, setPrevious] = useState({})

    const onSave = id => {
        props.setRows(state => {
            return props.rows.map(row => {
                if (row.id === id) {
                    if (row.isNewUser === true) {
                        const requestOptions = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                "first_name": row.first_name,
                                "last_name": row.last_name,
                                "email": row.email,
                            })
                        };
                        const url = '/api/v1/users'
                        fetch(url, requestOptions)
                            .then(response => response.json())
                            .then(data => UpdateUsers(props.rows, props.setRows, data))
                        row.isEditMode = false
                        return {...row, isEditMode: false};
                    } else {
                        const requestOptions = {
                            method: 'PUT',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                "id": row.id,
                                "first_name": row.first_name,
                                "last_name": row.last_name,
                                "email": row.email,
                                "created_at": row.created_at,
                                "updated_at": row.updated_at,
                                "is_active": row.is_active
                            })
                        };
                        const url = '/api/v1/users/' + row.id
                        fetch(url, requestOptions)
                            .then(response => response.json())
                            .then(data => UpdateUsers(props.rows, props.setRows, data))
                        row.isEditMode = false
                        return {...row, isEditMode: false};
                    }
                }
                return row;
            });
        });
    }

    const onToggleEditMode = (id, enter_from) => {
        props.setRows(state => {
            return props.rows.map(row => {
                if (row.id === id) {
                    return { ...row, isEditMode: !row.isEditMode };
                }
                return row;
            });
        });
    };

    // {e => onChange(e, row)}
    const onChange = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({ ...state, [row.id]: row }));
        }
        const value = e.target.value;
        const name = e.target.name;
        const { id } = row;
        const newRows = props.rows.map(row => {
            if (row.id === id) {
                return { ...row, [name]: value };
            }
            return row;
        });
        props.setRows(newRows);
    };

    const onRevert = id => {
        const newRows = props.rows.map(row => {
            if (row.id === id) {
                return previous[id] ? previous[id] : row;
            }
            return row;
        });
        props.setRows(newRows);
        setPrevious(state => {
            delete state[id];
            return state;
        });
        onToggleEditMode(id);
    };

    const onAdd = () => {
        props.setRows(current => [...current, {
            id: Math.floor(Math.random() * 1000000),
            first_name: "",
            last_name: "",
            email: "",
            created_at: "",
            updated_at: "",
            created_by: "",
            is_active: "",
            isEditMode: true,
            isNewUser: true
        }])
    }

    return (
        <div>
            <Box sx={{ paddingBottom:'10px' }}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => onAdd()}>Add User</Button>
            </Box>
        <TableContainer component={Paper}>
        <Table size="small" style={{ width: "100%" }}>
            <TableBody>
                <TableRow >
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created By</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                { props.rows.map(row => (
                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <CustomTableCell {...{ row, name: "first_name", onChange }} />
                        <CustomTableCell {...{ row, name: "last_name", onChange }} />
                        <CustomTableCell {...{ row, name: "email", onChange }} />
                        <TableCell component="th" scope="row">{row.created_by}</TableCell>
                        <TableCell component="th" scope="row">{row.created_at}</TableCell>
                        <TableCell component="th" scope="row">
                            {row.isEditMode ? (
                                <>
                                    <IconButton
                                        aria-label="save"
                                        onClick={() => onSave(row.id)}
                                    >
                                        <DoneIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="revert"
                                        onClick={() => onRevert(row.id)}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => onToggleEditMode(row.id)}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </TableContainer>
        </div>
)
}

function Users() {
    SetTitle("Users | Relay Hawk")
    const [rows, setRows] = useState([])
    const [users, setUsers] = useState([])
    useEffect(() => {
        let local_users = []
        fetch('/api/v1/users').then(res => res.json()).then(data => {
            setUsers(data.users)
            for (let user of data.users) {
                local_users.push(createData(user.id, user.first_name, user.last_name, user.email, UserIdToFriendly(user.created_by, users), user.created_at, user.updated_at, user.is_active, false))
            }
            setRows(local_users)
        });
    }, []);
    // todo: add support for adding Users rather than just editing
    return (
        <Layout>
            <Title>Users</Title>
            {/*<UsersTable users={users} rows={rows} setRows={setRows}/>*/}
            <UsersTable rows={rows} setRows={setRows}/>
        </Layout>
  );
}

export default Users;

import {useEffect} from "react";

// todo: figure out how to make this be the default title. It flashes "React App" before changing to this title.
export default function SetTitle(title) {
    if (!title) {
        title = "Relay Hawk"
    }
    useEffect(() => {
        document.title = title;
    });
}

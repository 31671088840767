import {Box} from "@mui/material";
import {Logo} from "./SignUp";
import SetTitle from "./components/SetTitle";

function SignUpWaitingApproval() {
    SetTitle("Waiting Approval | Relay Hawk")
    return (
        <Box sx={{
            background: '#0c5394',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center'
        }}>
            <Box sx={{
                width: '30rem',
                background: '#ffffff',
                borderRadius: '20px',
                display: 'block'
            }}>
                <Box sx={{
                    padding: '20px',
                    display: 'block',
                    alignItems: 'left',
                    textAlign: 'center',
                }}>
                    <Box sx={{ padding:'10px' }}>
                        <Logo />
                    </Box>
                    <Box sx={{ padding:'10px' }}>
                        Relay Hawk has received your request for an account. You will receive an email when your
                        company has been approved to use Relay Hawk's services.
                    </Box>
                </Box>
                </Box>
        </Box>
    )

}

export default SignUpWaitingApproval;
import {useEffect, useState} from "react";
import {TextField, FormGroup, FormControlLabel, Checkbox, Button, Box, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ReactComponent as LogoBlue} from './icons/logo-blue.svg'
import styled from 'styled-components';
import SetTitle from "./components/SetTitle";

const signup = {
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    accept_terms: false
}

export const Logo = styled(LogoBlue)`
    width: 10rem;
`


function SignUp() {
    SetTitle("Sign Up | Relay Hawk")
    const navigate = useNavigate();
    const [formDetails, setFormDetails] = useState(signup)

    useEffect(() => {
        fetch('/api/v1/users/me').then(res => res.json()).then(data => {
            setFormDetails({...formDetails,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email
            })
        });
    }, []);

    function signupOnClick(options) {
        const url = '/api/v1/signup'
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(options)
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => navigate('/payment'))
    }

    const onChange = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        setFormDetails({
            ...formDetails,
            [e.target.name]: e.target.value
        })
    };


    return (
        <Box sx={{
            background: '#0c5394',
            textAlign: 'center',
            alignItems: 'center',
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center'
        }}>
            <Box sx={{
                width: '30rem',
                background: '#ffffff',
                borderRadius: '20px',
                display: 'block'
            }}>
                <Box sx={{
                    padding: '20px',
                    display: 'block',
                    alignItems: 'left',
                    textAlign: 'center',
                }}>
                    <Box sx={{ padding:'10px' }}>
                        <Logo />
                    </Box>
                    <Box sx={{ padding:'10px' }}><TextField id="first_name" label="First Name" variant="outlined" value={formDetails.first_name} onChange={newValue => setFormDetails({...formDetails, first_name: newValue.target.value})}/></Box>
                    <Box sx={{ padding:'10px' }}><TextField id="last_name" label="Last Name" variant="outlined" value={formDetails.last_name} onChange={newValue => setFormDetails({...formDetails, last_name: newValue.target.value})}/></Box>
                    <Box sx={{ padding:'10px' }}><TextField id="company_name" label="Company Name" variant="outlined" onChange={newValue => setFormDetails({...formDetails, company_name: newValue.target.value})}/></Box>
                    <Box sx={{ padding:'10px' }}><Tooltip title={"You cannot change that email that you used to signup."}><TextField disabled id="email" label="email" variant="outlined" value={formDetails.email} onChange={e => onChange(e)}/></Tooltip></Box>
                    <Box sx={{ padding:'10px' }}>
                    {/* todo: add recaptcha */}
                        By signing up, you agree to the <a href="https://www.relayhawk.com/legal/terms-of-service">Terms of Service</a> and <a href="https://www.relayhawk.com/legal/privacy-policy">Privacy Policy</a>. <br /> I agree <Checkbox onChange={newValue => setFormDetails({...formDetails, accept_terms: newValue.target.checked})}/></Box>
                    <Box sx={{ padding:'10px' }}><Button variant="contained" onClick={() => signupOnClick(formDetails)}>Signup</Button></Box>
                </Box>
                </Box>
        </Box>
    )

}

export default SignUp;
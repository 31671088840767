import {useEffect, useState} from "react";
import Navigation from "./components/Navigation";
import {
    Box,
} from "@mui/material";
import Layout from "./components/Layout";




function Logout() {
    useEffect(() => {
        fetch('/api/v1/logout').then(res => res.json()).then(data => {
            window.location.replace(data.redirect_url)
        });
    }, []);

    return (
        <Layout>
            <Box>
                We are logging you out now.
            </Box>
        </Layout>
  );
}

export default Logout;

import {Box, Table, TableRow, TableCell} from "@mui/material";



function Test() {
    return (
        <Box sx={{ display: 'flex'}}>
            <Box sx={{padding: '1rem'}}>
                {/*Services*/}
                <Box sx={{ padding: '1rem', borderWidth: '1', borderColor: "#000000", borderRadius: '15px'}}>
                    <Box>
                        <b>10</b> Services open to the Interent
                    </Box>
                    <Box>
                        <b>4</b> Services to be reviewed
                    </Box>
                    <Box>
                        <b>3</b> services assigned to someone
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: '1rem' }}>
                {/*Configuration*/}
                <Box sx={{ padding: '1rem' }}>
                    <Box>
                        <b>13 IPs monitored</b>
                    </Box>
                    <Box>
                        Next scan is in <b>3 hours</b>
                    </Box>
                    <Box>
                        <u>Run scan now</u>
                    </Box>
                </Box>
            </Box>
        </Box>
  );
}

export default Test;

let ips = {
    "ips": [
    {
        "ip": "1.2.3.4",
        "created_by": 1,
        "created_at": "2022-08-21T12:34:56Z",
        "updated_at": "2022-08-21T12:34:56Z",
        "notes": "",
    },
    {
        "ip": "2.3.4.5",
        "created_by": 1,
        "created_at": "2022-08-21T12:34:56Z",
        "updated_at": "2022-08-21T12:34:56Z",
        "notes": "This is my data center IP",
    }
]
}

let ipsEmpty = {
    "ips": []
}

export function getIPs() {
    return ips;
}

export function getIPsEmpty() {
    return ipsEmpty
}

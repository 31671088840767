import './App.css';
import Navigation from "./components/Navigation";
import Title from "./Title";
import Paper from "@mui/material/Paper";
import {TableContainer, Table, TableBody, TableRow, TableCell} from "@mui/material";
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";
import {UserIdToFriendly} from "./App";
import Layout from "./components/Layout";
import SetTitle from "./components/SetTitle";


function WebServicesTable(props) {
    return (
        <TableContainer component={Paper}>
            <Table size="small" style={{ width: "100%" }}>
                <TableBody>
                    <TableRow >
                        <TableCell>IP</TableCell>
                        <TableCell>Port</TableCell>
                        <TableCell>Protocol</TableCell>
                        <TableCell>Service</TableCell>
                        <TableCell>First Seen</TableCell>
                        <TableCell>Should be open?</TableCell>
                        <TableCell>Assigned to</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    {props.webServices.map(row => (
                        <TableRow key={row.service_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{row.ip}</TableCell>
                            <TableCell component="th" scope="row">{row.port}</TableCell>
                            <TableCell component="th" scope="row">{row.protocol}</TableCell>
                            <TableCell component="th" scope="row">{row.service}</TableCell>
                            <TableCell component="th" scope="row">{row.discovered_date}</TableCell>
                            <TableCell component="th" scope="row">{row.user_configuration.should_be_open}</TableCell>
                            <TableCell component="th" scope="row">{UserIdToFriendly(row.user_configuration.assigned_to, props.users)}</TableCell>
                            <TableCell component="th" scope="row"><Button variant="contained">Review</Button></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function WebServices() {
    SetTitle("Web Services | Relay Hawk")
    const [webServices, setWebServices] = useState([])
    useEffect(() => {
        fetch('/api/v1/services').then(res => res.json()).then(data => {
            setWebServices(data.services)
        });
    }, []);

    const [users, setUsers] = useState([]);
    useEffect(() => {
        fetch('/api/v1/users').then(res => res.json()).then(data => {
            setUsers(data.users);
        });
    }, []);

    return (
        <Layout>
            <Title>Web Services</Title>
            <WebServicesTable webServices={webServices} users={users} />
        </Layout>
    );
}

export default WebServices;

let webservices = {
    "total_count": 5,
    "require_review_count": 2,
    "require_action_count": 0,
    "last_scan": "2022-08-21T12:34:56Z",
    "next_scan": "2022-08-21T12:34:56Z",
    "services": [
        {
            "service_id": 1345612346,
            "ip": "1.2.3.4",
            "port": 443,
            "protocol": "tcp",
            "service": "www",
            "discovered_date": "2022-08-21T12:34:56Z",
            "closed_date": null,
            "screenshot": "https://url/to/screenshot",
            "is_open": true,
            "user_configuration": {
                "should_be_open": null,
                "not_reviewed": true,
                "last_modified_by": null,
                "assigned_to": null
            },
        },
        {
            "service_id": 9819211,
            "ip": "1.2.3.4",
            "port": 443,
            "protocol": "tcp",
            "service": "www",
            "discovered_date": "2022-08-21T12:34:56Z",
            "closed_date": null,
            "screenshot": "https://url/to/screenshot",
            "is_open": true,
            "user_configuration": {
                "should_be_open": null,
                "not_reviewed": true,
                "last_modified_by": null,
                "assigned_to": null
            },
        },
        {
            "service_id": 121912,
            "ip": "1.2.3.4",
            "port": 80,
            "protocol": "tcp",
            "service": "www",
            "discovered_date": "2022-08-21T12:34:56Z",
            "closed_date": null,
            "screenshot": "https://url/to/screenshot",
            "is_open": true,
            "user_configuration": {
                "should_be_open": "unsure",
                "not_reviewed": false,
                "last_modified_by": 1,
                "assigned_to": 2
            },
        }
]
}

let webServicesEmpty = {
    "total_count": 0,
    "require_review_count": 0,
    "require_action_count": 0,
    "last_scan": "",
    "next_scan": "",
    "services": []
}

export function getWebServices() {
    return webservices;
}

export function getWebServicesEmpty() {
    return webServicesEmpty;
}
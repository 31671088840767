import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './index.css';
import App from './App';
import IPs from "./IPs";
import reportWebVitals from './reportWebVitals';
import WebServices from "./WebServices";
import Users from "./Users";
import SignUp from "./SignUp";
import Test from "./Test"
import Logout from "./Logout"
import SignUpWaitingApproval from "./SignUpWaiting";
import ValidateEmail from "./ValidateEmail";
import Payment from "./Payment";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain="relayhawk.auth0.com"
        clientId="kWOIzNBUo8H6QEULp8bZdGVd1xpX6gWZ"
        redirectUri="http://localhost:8888/callback"
    >
      <React.StrictMode>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<App />} />
                  <Route path="/WebServices" element={<WebServices />} />
                  <Route path="/IPs" element={<IPs />} />
                  <Route path="/Users" element={<Users />} />
                  <Route path="/complete-signup" element={<SignUp />} />
                  <Route path='/payment' element={<Payment />} />
                  <Route path="/signup-waiting-approval" element={<SignUpWaitingApproval />} />
                  <Route path="/test" element={<Test />} />
                  <Route path='/logout' element={<Logout />} />
                  <Route path='/validate-email' element={<ValidateEmail />} />
              </Routes>
          </BrowserRouter>
      </React.StrictMode>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
